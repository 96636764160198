import React from 'react';

import DemoCTA from '../components/demoCta';
import Layout from '../components/layout';

const data = [
  {
    heading: 'Our Story',
    text: `Adapta's founders started the company shortly after
  graduating from engineering programs at the University of Waterloo.
  While studying at the university, we realized the importance of the
  role transit played in connecting students to the rest of the
  community. Through the university's co-op program, we also had the
  opportunity to experience transit, and see how information systems
  can make or break a system's ridership. We founded Adapta Solutions
  Inc. with the hopes of providing every transit agency with
  cost-effective solutions to solve this problem.`,
  },
  {
    heading: 'Our Vision',
    text: `
  We believe that transit systems should be equitable for all. Modern
  technology has allowed us to live in a more-connected world than ever before.
  We want to help bring that technology into the public transit ecosystem. We
  believe that the transit experience should not be influenced by a
  municipality's budget, an agency's ridership, or a rider's physical
  abilities. As a result, we focus on technologies that make transit systems
  more accessible by providing affordable solutions that scale as your transit
  system grows.
  `,
  },
  {
    heading: 'Our Values',
    text: `Adapta is proud to offer a combination of innovative software and
  hardware solutions to help growing transit systems. We focus
  primarily on improving information dissemination between transit
  authorities and their riders. While we have complete systems to help
  your transit system, we are also happy to integrate our solutions
  into your existing tools and processes.`,
  },
];

export default function About() {
  return (
    <Layout
      title="About Us"
      mainClasses="py-16 bg-gray-50 overflow-hidden lg:py-24"
    >
      <div className="relative pb-6 lg:pb-12 sm:pb-8">
        <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          About Adapta
        </h3>
      </div>
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl divide-y divide-brandgreen">
        {data.map((x, i) => {
          return (
            <div className={`${i === 0 ? '' : 'mt-6'}`}>
              <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                {x.heading}
              </h4>
              <p className="mt-3 text-lg leading-7">{x.text}</p>
            </div>
          );
        })}
      </div>

      <div className="mt-10">{DemoCTA()}</div>
    </Layout>
  );
}
