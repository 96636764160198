import React from 'react';
import { Link } from 'gatsby';

export default function DemoCTA() {
  return (
    <div className="mt-4 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
      <div className="max-w-md mx-auto lg:max-w-5xl">
        <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
          <div className="flex-1">
            <div>
              <h3 className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-white text-gray-800">
                Ready to get started?
              </h3>
            </div>
            <div className="mt-4 text-lg leading-7 text-gray-600">
              See Adapta's solutions in action. Book a demo today to get your
              community
              <strong className="pl-1 font-semibold text-brandblue">
                moving
              </strong>
              .
            </div>
          </div>
          <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
            <Link
              to="/contact"
              className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
